function ErrorScreen() {

    return (
        <div>
            <h1>Error!</h1>
            <h2>An issue has occurred.</h2> 
            <h4>Contact the IT team if the issue persists</h4>
            <h4>Please go to another section of the web app or try again later</h4>
        </div>
    )
}

const displayError = (errorMessage) => {
    if(errorMessage !== "") {
        console.log(errorMessage);
    } else {
        console.log("There was an error.")
    }
    
    return <ErrorScreen/>
}

export default displayError;