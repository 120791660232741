import gql from 'graphql-tag';

const GET_NOTIFICATIONS = gql`
    query GetNotifications {
        getNotifications {
            Notifications {
                id
                group_id
                email
                action_type
                section
                new_content
                added_on
            }
            Customer {
                last_read_notification
            }
        }
    }`;
export default GET_NOTIFICATIONS